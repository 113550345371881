.container {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  background-color: white;
  pointer-events: all;
  padding: 20px;
  border-radius: 10px;
  padding-top: 0;
  border: 1px solid white;
}

/* Styles for form labels */
.form-label {
  font-weight: bold;
  color: #333;
}

.inputDiv {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styles for form inputs */
.form-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

/* Styles for the submit button */
.form-submit-button {
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.form-submit-button:hover {
  background-color: #0056b3;
}

/* Styles for form errors */
.form-error {
  border: 1px solid #e42323;
}

.errorP {
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #e42323;
  max-width: 260px;
}
