.controls {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    height: 29px;
    margin-left: 17px;
    margin-top: 10px;
    outline: none;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
    margin-right: 1%;
    padding:1em
  }

  .controls:focus {
    border-color: #4d90fe;
  }

  .title {
    font-weight: bold;
  }

  /* #infowindow-content { 
    display: none;
  } */

  #gMap #infowindow-content {
    display: inline;
  }
