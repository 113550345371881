.name {
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  width: 100%;
  border-top: 1px solid gray;
}

.row:nth-child(1) {
  margin-top: 20px;
}

.optionP {
  margin: 0;
  font-size: 14px;
}

.button {
  padding: 8px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.button:hover {
  background-color: #0056b3;
}

.input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.label {
  font-size: 14px;
}

.input {
  max-width: 150px;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 14px;
}

.errorP {
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #e42323;
  max-width: 260px;
}
